$(function() {
    $('img.lazy, .lazy').lazy()
});

// modal 開啟時背景不可滑動
$('.modal').on('show.bs.modal', function (e) {
    $('body').addClass('overflow-hidden')
})
$('.modal').on('hide.bs.modal', function (e) {
    $('body').removeClass('overflow-hidden')
})

$(document).ready(function() {

    $('.js-dropdown-hover').each(function(){
        $(this).mouseenter(function(){
            $(this).siblings('.dropdown-menu').addClass('show');
        })
        $(this).closest('.btn-group, .dropdown').mouseleave(function(){
            $(this).find('.dropdown-menu').removeClass('show');
        })
    })

    $('.js-dropdown-hover').click(function(){
        window.location.replace($(this).attr('href'));
    })

    windowHeightSet()

    $('.js-select2').select2();

    // 解決mmenu套件渲染時 畫面浮動問題
    mmenuFirstStartRemoveHide('#main-menu')
});

function mmenuFirstStartRemoveHide(id){
	var href = '[href=' + "'" +  id + "'" + ']'
	if(id){
		$(href).click(function(){
			$(id).removeClass('hide');
		})
	}else{
		return false
	}
}

$(function(){
    var topBtn = jQuery('#pagetop');
    topBtn.hide();
    jQuery(window).scroll(function () {
        if (jQuery(this).scrollTop() > 100) {
            topBtn.fadeIn();
        } else {
            topBtn.fadeOut();
        }
    });
    
    topBtn.click(function () {
        jQuery('body,html').animate({
            scrollTop: 0
        }, 500);
        return false;
    });

    var agent = navigator.userAgent;
    if (agent.indexOf('iPhone') > 0 ||
        (agent.indexOf('Android') > 0 && agent.indexOf('Mobile') > 0)) {
        jQuery("a").attr("target","_top");
    }
});

new Swiper('.swiper-products', {
    lazy: true,
    observer: true,
    observeParents: true,
    preloadImages: false,
    slidesPerView: 1,
    slidesPerGroup: 1,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
});

document.addEventListener(
    "DOMContentLoaded", () => {
        const menu = new Mmenu("#main-menu", {
            clone: false,
            navbar: {
                title: 'Webike 摩托百貨'
              },
              extensions: [
                "pagedim-black",
                "theme-white",
                "position-front",
                "border-full"
              ],
              setSelected: true,
              scrollBugFix: {
                  fix: false
              }
        });

        const api = menu.API;

        $(document).on('click','.js-category-btn .js-fake-mm-btn',function(){
            NProgress.start()
            var url = $(this).prev("a[url-data]").attr('url-data')
            var panel =$(this)
            ajaxGetNextCategory(url,panel,renderMmenuSubItems);
        })

        function renderMmenuSubItems(panelBtn,data){

            const panelId = '#' + panelBtn.closest('.mm-panel').attr('id')
            const listitem = panelBtn.closest('.js-category-btn')
            const panel = document.querySelector( panelId )
            const parent = panelBtn.closest('.js-category-btn')

            // 因為等等點擊的元素會被重置，並且最後要做模擬點擊，所以先再點擊元素的母元素做一個站存的偽標籤，等到確定移動到下一個頁面後 再把偽標籤刪除
            parent.addClass('js-memory-tag');

            //    Create the new listview.
            const listview = document.createElement( "ul" );

            var listVal = ''

            // 組合html
            for(var item in data){
                if(data[item]['node']){
                    listVal += '<li class="js-category-btn"><a href="https://exp.webike.tw/ca/' + 
                    data[item]['category'] + 
                    '" title="' + 
                    data[item]['name'] + 
                    '" url-data="' + 
                    data[item]['category'] + 
                    '">' + 
                    data[item]['name']  + 
                    '</a><a class="mm-btn mm-btn_next mm-listitem__btn js-fake-mm-btn" href="javascript:void(0)"></a></li>'
                }else{
                    listVal += '<li class="js-category-btn"><a href="https://exp.webike.tw/ca/' + 
                    data[item]['category'] + 
                    '" title="' + 
                    data[item]['name'] + 
                    '" url-data="' + 
                    data[item]['category'] + 
                    '">' + 
                    data[item]['name']  + 
                    '</a></li>'
                }
            }


            listview.innerHTML = listVal;

            //    Add the listview to the listitem.

            panelBtn.remove();
            listitem.append( listview );

            //    Update the listview.
            api.initPanel( panel )

            // 初始化新的pannel之後要移動到 新的pannel 藉由一開始創建的暫存矛點來移動
            const targetId = $('.js-memory-tag').find('.mm-listitem__btn').attr('href');
            const targetPanel = document.querySelector( targetId );

            api.openPanel( targetPanel )

            // 移動完後要刪除暫存元素
            $('.js-memory-tag').removeClass('js-memory-tag');
        }
    }
)

function windowHeightSet(){
    var windowHeight = $(window).height()
    var footerHeight = $('.js-footer').innerHeight()
    var wrapHeight = windowHeight-footerHeight

    $('.wrap').css('min-height', wrapHeight);
}

function ATSreset(){
    var target = $('.js-owl-reset')
    var item = target.find('.owl-carouse-advertisement .item')
    var img = target.find('.owl-carouse-advertisement .item img')
    if(target.length > 0){
        target.find('.owl-carouse-advertisement').addClass('swiper-wrapper')
        item.addClass('swiper-slide')
        img.addClass('swiper-lazy')

        $.each(item,function(i){
            i++
            var t = target.find('.owl-carouse-advertisement .item:nth-of-type(' + i + ') img')
            var url = t.attr('data-owl-src')
            t.attr('data-src',url)
        })

        var swiper = new Swiper('.js-owl-reset', {
                lazy: true,
                observer: true,
                observeParents: true,
                preloadImages: false,
                loop: true,
                slidesPerView: 1,
                slidesPerGroup: 1,
                navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    }
}

function ajaxGetNextCategory(data,panel,todo){
    var url = '/api/getCategoryDetail/' + data
    $.ajax({
        type: "get",
        url: url,
        success: function (response) {
            todo(panel,response)
            NProgress.done()
        }
    });
}

function addParameter(url, parameterName, parameterValue, atStart/*Add param before others*/){
    replaceDuplicates = true;
    if(url.indexOf('#') > 0){
        var cl = url.indexOf('#');
        urlhash = url.substring(url.indexOf('#'),url.length);
    } else {
        urlhash = '';
        cl = url.length;
    }
    sourceUrl = url.substring(0,cl);

    var urlParts = sourceUrl.split("?");
    var newQueryString = "";

    if (urlParts.length > 1)
    {
        var parameters = urlParts[1].split("&");
        for (var i=0; (i < parameters.length); i++)
        {
            var parameterParts = parameters[i].split("=");
            if (!(replaceDuplicates && parameterParts[0] == parameterName))
            {
                if (newQueryString == "")
                    newQueryString = "?";
                else
                    newQueryString += "&";
                newQueryString += parameterParts[0] + "=" + (parameterParts[1]?parameterParts[1]:'');
            }
        }
    }
    if (newQueryString == "")
        newQueryString = "?";

    if(atStart){
        newQueryString = '?'+ parameterName + "=" + parameterValue + (newQueryString.length>1?'&'+newQueryString.substring(1):'');
    } else {
        if (newQueryString !== "" && newQueryString != '?')
            newQueryString += "&";
        newQueryString += parameterName + "=" + (parameterValue?parameterValue:'');
    }
    return urlParts[0] + newQueryString + urlhash;
};